<template>
    <a href="#0" class="scrollToTop"><i class="fas fa-angle-up"></i></a>

<!--    Preloader убрал пока - чтобы ошибки не было - лучше свой сделать -->

<!--    <div class="overlay"></div>-->
<!--    <div class="preloader">-->
<!--        <div class="preloader-inner">-->
<!--            <div class="preloader-icon">-->
<!--                <span></span>-->
<!--                <span></span>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
  <!--============= Banner Section Starts Here =============-->
    <section class="banner-11 oh pos-rel" id="homepage">
        <div
                class="extra-bg bg_img"
                data-background="./assets/images/banner/banner11/banner11-bg.jpg"
        ></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="banner-content-11">
                        <h1 class="title">
                            Telegram Prime - лучшая программа для продвижения в Telegram
                        </h1>
                        <p>Зарабатывайте, предлагая услуги по продвижению в Telegram</p>
                        <p>Продвигайте свои проекты и собирайте лиды!</p>
                        <p>Оставьте свой e-mail для получения бесплатной демо-версии:</p>
                        <form class="banner-search-form style-two">
                            <input type="text" placeholder="Ваш e-mail" />
                            <button type="submit"><i class="flaticon-right"></i></button>
                        </form>
                    </div>
<!--                    в ошибку падают счетчики - лучше свой сделать -->

<!--                    <div class="banner-odometer-two">-->
<!--                        <div class="counter-item">-->
<!--                            <div class="counter-thumb">-->
<!--                                <img src="./assets/images/icon/counter1.png" alt="icon" />-->
<!--                            </div>-->
<!--                            <div class="counter-content">-->
<!--                                <h2 class="title"><span class="counter">17501</span></h2>-->
<!--                                <span>пользователей</span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="counter-item">-->
<!--                            <div class="counter-thumb">-->
<!--                                <img src="./assets/images/icon/counter2.png" alt="icon" />-->
<!--                            </div>-->
<!--                            <div class="counter-content">-->
<!--                                <h2 class="title"><span class="counter">1987</span></h2>-->
<!--                                <span>использований в день</span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
                <div class="col-lg-6 d-none d-lg-block">
                    <div class="banner-thumb-11">
                        <div class="main-thumb wow slideInLeft">
                            <img
                                    src="./assets/images/banner/banner11/banner11.png"
                                    alt="banner"
                            />
                        </div>
                        <div
                                class="graph wow bounceInDown"
                                data-wow-delay="3s"
                                data-wow-duration="1s"
                        >
                            <img
                                    src="./assets/images/banner/banner11/graph.png"
                                    alt="banner"
                            />
                        </div>
                        <div
                                class="boy wow slideInRight"
                                data-wow-delay="1s"
                                data-wow-duration="1s"
                        >
                            <img
                                    src="./assets/images/banner/banner11/boy.png"
                                    alt="banner"
                            />
                        </div>
                        <div
                                class="girl wow slideInUp"
                                data-wow-delay="1.5s"
                                data-wow-duration="1s"
                        >
                            <img
                                    src="./assets/images/banner/banner11/girl.png"
                                    alt="banner"
                            />
                        </div>
                        <div
                                class="tree1 wow slideInUp"
                                data-wow-delay="2s"
                                data-wow-duration="1s"
                        >
                            <img
                                    src="./assets/images/banner/banner11/tree1.png"
                                    alt="banner"
                            />
                        </div>
                        <div
                                class="tree2 wow slideInUp"
                                data-wow-delay="2.5s"
                                data-wow-duration="1s"
                        >
                            <img
                                    src="./assets/images/banner/banner11/tree2.png"
                                    alt="banner"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  <!--============= Banner Section Ends Here =============-->



  <!--============= How It Works Section Starts Here =============-->
    <div
            class="amazing-feature padding-top padding-bottom pos-rel oh"
            id="feature"
    >
        <div
                class="rocket d-none d-xl-block"
                data-paroller-factor="0.70"
                data-paroller-factor-lg="-0.70"
                data-paroller-type="foreground"
                data-paroller-direction="horizontal"
        >
            <img src="./assets/images/feature/rocket.png" alt="feature" />
        </div>
        <div class="am-obj">
            <img src="./assets/images/feature/am-obj2.png" alt="feature" />
        </div>
        <div class="am-obj two">
            <img src="./assets/images/feature/am-obj.png" alt="feature" />
        </div>
        <div class="container features-container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-xl-7">
                    <div class="section-header">
                        <h5 class="cate">Много крутых функций</h5>
                        <h2 class="title">За это вы полюбите Prime</h2>
                        <p class="mw-100">
                            Telegram Prime - совершенно новый софт, вобравший в себя опыт
                            всех прошлых версий.
                        </p>
                    </div>
                </div>
                <div class="col-12 features-container-column">
                    <div class="row justify-content-center mb-30">
                        <div class="col-sm-6 col-lg-3">
                            <div class="am-item active">
                                <div class="am-thumb">
                                    <img src="./assets/images/2.webp" alt="feature" />
                                </div>
                                <div class="am-content">
                                    <h5 class="title mb-3">Стабильность</h5>
                                    <p>Собирайте аудиторию из любых групп Telegram</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <div class="am-item">
                                <div class="am-thumb">
                                    <img src="./assets/images/3.webp" alt="feature" />
                                </div>
                                <div class="am-content">
                                    <h5 class="title mb-3">Удобство в использовании</h5>
                                    <p>Ваши боты будут отвечать на сообщения круглосуточно</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center mb-30">
                        <div class="col-sm-6 col-lg-3">
                            <div class="am-item active">
                                <img
                                        alt="Накрутка просмотров"
                                        class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                        src="./assets/images/4.png"
                                        height="128px"
                                        onmousedown="return false"
                                        width="128px"
                                />
                                <h5 class="title mb-3">Накрутка просмотров</h5>
                                <p>
                                    Используйте аккаунты, чтобы накручивать просмотры на
                                    публикациях в каналах
                                </p>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <div class="am-item active">
                                <img
                                        alt="Сбор только активной аудитории"
                                        class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                        src="./assets/images/5.png"
                                        height="128px"
                                        onmousedown="return false"
                                        width="128px"
                                />
                                <h5 class="title mb-3">Сбор только активной аудитории</h5>
                                <p>
                                    Собирайте живую аудиторию из любых телеграм-групп, используя
                                    функцию-сбор писавших в чат
                                </p>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <div class="am-item active">
                                <img
                                        alt="Приглашение рефералов в ботов"
                                        class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                        src="./assets/images/6.png"
                                        height="128px"
                                        onmousedown="return false"
                                        width="128px"
                                />
                                <h5 class="title mb-3">Приглашение рефералов в ботов</h5>
                                <p>Приглашайте свои аккаунты по реферальной ссылке в ботов</p>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center mb-30">
                        <div class="col-sm-6 col-lg-3">
                            <div class="am-item active">
                                <img
                                        alt="Инвайтер в группы"
                                        class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                        src="./assets/images/7.png"
                                        height="128px"
                                        onmousedown="return false"
                                        width="128px"
                                />
                                <h5 class="title mb-3">Инвайтер в группы</h5>
                                <p>
                                    Добавляйте в свои группы (чаты) Telegram собранную целевую
                                    аудиторию, отфильтровав ее по активности
                                </p>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <div class="am-item active">
                                <img
                                        alt="Рассылка сообщений"
                                        class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                        src="./assets/images/8.png"
                                        height="128px"
                                        onmousedown="return false"
                                        width="128px"
                                />
                                <h5 class="title mb-3">Рассылка сообщений</h5>
                                <p>
                                    Можно делать массовые рассылки своих рекламных сообщений в
                                    лички ранее собранной аудитории, а также в чаты
                                </p>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <div class="am-item active">
                                <img
                                        alt="Массовые накрутки"
                                        class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                        src="./assets/images/9.png"
                                        height="128px"
                                        onmousedown="return false"
                                        width="128px"
                                />
                                <h5 class="title mb-3">Массовые накрутки</h5>
                                <p>
                                    С помощью программы Telegram soft можно накрутить
                                    подписчиков на своих каналах и просмотры на посты
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center mb-30">
                    <div class="col-sm-6 col-lg-3">
                        <div class="am-item active">
                            <img
                                    alt="Парсер аудитории"
                                    class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                    src="./assets/images/10.png"
                                    onmousedown="return false"
                                    width="128px"
                            />
                            <h5 class="title mb-3">Парсер аудитории</h5>
                            <p>
                                Соберите вашу целевую аудиторию с разных телеграм-чатов и
                                отфильтруйте её по активности
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="am-item active">
                            <img
                                    alt="Накрутка реакций и голосований"
                                    class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                    src="./assets/images/11.png"
                                    height="128px"
                                    onmousedown="return false"
                                    width="128px"
                            />
                            <h5 class="title mb-3">Накрутка реакций и голосований</h5>
                            <p>
                                Участвуйте в анонимных голосованиях и ставьте разные реакции
                                на постах в канале
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="am-item active">
                            <img
                                    alt="Чекер номеров"
                                    class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                    src="./assets/images/12.png"
                                    onmousedown="return false"
                                    width="128px"
                            />
                            <h5 class="title mb-3">Чекер номеров</h5>
                            <p>
                                С помощью чекера можно проверить номера мобильных телефонов на
                                наличие их в телеграме, тем самым еще больше увеличив свою
                                базу
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center mb-30">
                    <div class="col-sm-6 col-lg-3">
                        <div class="am-item active">
                            <img
                                    alt="Релогин сессии"
                                    class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                    src="./assets/images/13.png"
                                    height="128px"
                                    onmousedown="return false"
                                    width="128px"
                            />
                            <h5 class="title mb-3">Релогин сессии</h5>
                            <p>
                                Позволит с легкостью использовать аккаунты одновременно,
                                подключаясь к ним из разных мест
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="am-item active">
                            <img
                                    alt="Конвертер Session - Tdata"
                                    class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                    src="./assets/images/14.png"
                                    height="128px"
                                    onmousedown="return false"
                                    width="128px"
                            />
                            <h5 class="title mb-3">Конвертер Session - Tdata</h5>
                            <p>
                                Конвертируйте свои сессии в TData для использования на
                                Telegram Desktop
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="am-item active">
                            <img
                                    alt="Конвертер Tdata - Session"
                                    class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                    src="./assets/images/14.png"
                                    height="128px"
                                    onmousedown="return false"
                                    width="128px"
                            />
                            <h5 class="title mb-3">Конвертер Tdata - Session</h5>
                            <p>
                                Конвертируйте свои TData в сессии для использования в нашей
                                программе
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center mb-30">
                    <div class="col-sm-6 col-lg-3">
                        <div class="am-item active">
                            <img
                                    alt="Мощнейший Гео-парсер"
                                    class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                    src="./assets/images/15.png"
                                    height="128px"
                                    onmousedown="return false"
                                    width="128px"
                            />
                            <h5 class="title mb-3">Мощнейший Гео-парсер</h5>
                            <p>
                                Позволит Вам собрать аудиторию Telegram пользователей в
                                определённой геолокации по всему миру
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="am-item active">
                            <img
                                    alt="Парсинг по БИО"
                                    class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                    src="./assets/images/16.png"
                                    height="128px"
                                    onmousedown="return false"
                                    width="128px"
                            />
                            <h5 class="title mb-3">Парсинг по БИО</h5>
                            <p>
                                Собирайте аудиторию по ключевым словам в БИО пользователей
                                Telegram и получайте еще более качественную аудиторию
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="am-item active">
                            <img
                                    alt="Автоматизация vTope"
                                    class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                    src="./assets/images/17.png"
                                    height="128px"
                                    onmousedown="return false"
                                    width="128px"
                            />
                            <h5 class="title mb-3">Автоматизация vTope</h5>
                            <p>
                                Подключайтесь к наикрупнейшей сети по накрутке vTope и
                                зарабатывайте на просмотрах, реакциях и подписках
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center mb-30">
                    <div class="col-sm-6 col-lg-3">
                        <div class="am-item active">
                            <img
                                    alt="Авто-написания вступившим в чат"
                                    class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                    src="./assets/images/18.png"
                                    height="128px"
                                    onmousedown="return false"
                                    width="128px"
                            />
                            <h5 class="title mb-3">Автонаписания вступившим в чат</h5>
                            <p>
                                Используйте уникальнейшую возможность первым перехватить
                                свежего клиента
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="am-item active">
                            <img
                                    alt="Автореакции к выходящим постам в канале"
                                    class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                    src="./assets/images/19.png"
                                    height="128px"
                                    onmousedown="return false"
                                    width="128px"
                            />
                            <h5 class="title mb-3">
                                Автореакции к выходящим постам в канале
                            </h5>
                            <p>
                                С этой функцией Вы всегда будете первым поставившим реакцию на
                                новый пост
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="am-item active">
                            <img
                                    alt="Автокомментарии в каналах"
                                    class="img-fluid mb-4 lzl wp-dark-mode-ignore"
                                    src="./assets/images/20.png"
                                    height="128px"
                                    onmousedown="return false"
                                    width="128px"
                            />
                            <h5 class="title mb-3">Автокомментарии в каналах</h5>
                            <p>
                                Как правило именно первые коментарии наиболее часто
                                просматриваемые, так пусть он будет именно Ваш!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <!--============= How It Works Section Starts Here =============-->

  <!--============= How It Works Section Starts Here =============-->
    <section
            class="work-section padding-bottom bg_img mb-md-95 pb-md-0"
            data-background="./assets/images/work/work-bg2.jpg"
            id="how"
    >
        <div class="oh padding-top pos-rel">
            <div class="top-shape d-none d-lg-block">
                <img src="./assets/css/img/work-shape.png" alt="css" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-xl-7">
                        <div class="section-header left-style cl-white">
                            <h5 class="cate">Хочу Prime</h5>
                            <h2 class="title">
                                Как мне купить Prime<br />
                                и приступить к работе?
                            </h2>
                            <p>Это проще, чем вы думаете. Следуйте простым шагам</p>
                        </div>
                    </div>
                </div>
                <div class="work-slider owl-carousel owl-theme" data-slider-id="2">
                    <div class="work-item">
                        <div class="work-thumb">
                            <img src="./assets/images/work/work1.png" alt="work" />
                        </div>
                        <div class="work-content cl-white">
                            <h3 class="title">Выбрать тариф</h3>
                            <p>У нас есть три тарифа: демо, месячный и вечный</p>
                            <a href="#pricing" class="get-button white light"
                            >Купить <i class="flaticon-right"></i
                            ></a>
                        </div>
                    </div>

                    <div class="work-item">
                        <div class="work-thumb">
                            <img src="./assets/images/work/work1.png" alt="work" />
                        </div>
                        <div class="work-content cl-white">
                            <h3 class="title">Переходим к оплате</h3>
                            <p>
                                Вы можете приобрести нужный вам тариф сразу на сайте указав
                                почту куда придет лицензионный ключ и файл установщик.
                            </p>
                            <a href="#pricing" class="get-button white light"
                            >Купить<i class="flaticon-right"></i
                            ></a>
                        </div>
                    </div>
                    <div class="work-item">
                        <div class="work-thumb">
                            <img src="./assets/images/work/work1.png" alt="work" />
                        </div>
                        <div class="work-content cl-white">
                            <h3 class="title">Доступные методы оплаты</h3>
                            <p>
                                Вы можете оплатить криптовалютой или другим доступным способ
                                из платежных систем.
                            </p>
                            <a href="#pricing" class="get-button white light"
                            >Купить<i class="flaticon-right"></i
                            ></a>
                        </div>
                    </div>
                    <div class="work-item">
                        <div class="work-thumb">
                            <img src="./assets/images/work/work1.png" alt="work" />
                        </div>
                        <div class="work-content cl-white">
                            <h3 class="title">Появились вопросы?</h3>
                            <p>Свяжитесь с нашим технической поддержке в онлайн чате.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="count-slider owl-thumbs" data-slider-id="2">
                <div class="count-item">
                    <span class="serial">01</span>
                    <h5 class="title">Выбрать тариф</h5>
                </div>
                <div class="count-item">
                    <span class="serial">02</span>
                    <h5 class="title">Оплата</h5>
                </div>
                <div class="count-item">
                    <span class="serial">03</span>
                    <h5 class="title">Доступные методы оплаты</h5>
                </div>
                <div class="count-item">
                    <span class="serial">04</span>
                    <h5 class="title">Появились вопросы?</h5>
                </div>
            </div>
        </div>
    </section>
  <!--============= How It Works Section Ends Here =============-->

  <!--============= Tool Section Starts Here =============-->
    <section class="tool-section padding-bottom padding-top">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-lg-6 col-xl-5 rtl d-none d-lg-block">
                    <img src="./assets/images/recharge/tool.png" alt="recharge" />
                </div>
                <div class="col-lg-6">
                    <div class="section-header left-style">
                        <h5 class="cate">Откройте для себя мощный инструмент</h5>
                        <h2 class="title">Наши возможности</h2>
                    </div>
                    <div class="tool-wrapper mb-40">
                        <div class="tool-slider mb-30 owl-carousel owl-theme">
                            <div class="to-access-item style-two">
                                <div class="to-access-thumb">
                                    <span class="anime"></span>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/recharge/1.png"
                                                alt="recharge"
                                        />
                                    </div>
                                </div>
                                <h5 class="title">ИИ который подготавливает лидов</h5>
                            </div>
                            <div class="to-access-item style-two">
                                <div class="to-access-thumb">
                                    <span class="anime"></span>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/recharge/1.png"
                                                alt="recharge"
                                        />
                                    </div>
                                </div>
                                <h5 class="title">Автоотвечик</h5>
                            </div>
                            <div class="to-access-item style-two">
                                <div class="to-access-thumb">
                                    <span class="anime"></span>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/recharge/2.png"
                                                alt="recharge"
                                        />
                                    </div>
                                </div>
                                <h5 class="title">Рассылка сообщений</h5>
                            </div>
                            <div class="to-access-item style-two">
                                <div class="to-access-thumb">
                                    <span class="anime"></span>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/recharge/3.png"
                                                alt="recharge"
                                        />
                                    </div>
                                </div>
                                <h5 class="title">Работа по ID</h5>
                            </div>
                            <div class="to-access-item style-two">
                                <div class="to-access-thumb">
                                    <span class="anime"></span>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/recharge/4.png"
                                                alt="recharge"
                                        />
                                    </div>
                                </div>
                                <h5 class="title">Работа с комментариями</h5>
                            </div>
                            <div class="to-access-item style-two">
                                <div class="to-access-thumb">
                                    <span class="anime"></span>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/recharge/5.png"
                                                alt="recharge"
                                        />
                                    </div>
                                </div>
                                <h5 class="title">Создание ботов</h5>
                            </div>
                            <div class="to-access-item style-two">
                                <div class="to-access-thumb">
                                    <span class="anime"></span>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/recharge/6.png"
                                                alt="recharge"
                                        />
                                    </div>
                                </div>
                                <h5 class="title">Отправка жалоб</h5>
                            </div>
                            <div class="to-access-item style-two">
                                <div class="to-access-thumb">
                                    <span class="anime"></span>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/recharge/7.png"
                                                alt="recharge"
                                        />
                                    </div>
                                </div>
                                <h5 class="title">Многопоточность</h5>
                            </div>
                            <div class="to-access-item style-two">
                                <div class="to-access-thumb">
                                    <span class="anime"></span>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/recharge/8.png"
                                                alt="recharge"
                                        />
                                    </div>
                                </div>
                                <h5 class="title">Многоязычность</h5>
                            </div>
                        </div>
                    </div>
                    <a href="#pricing" class="button-3 long-light"
                    >Хочу купить! <i class="flaticon-right"></i
                    ></a>
                </div>
            </div>
        </div>
    </section>
  <!--============= Tool Section Ends Here =============-->

  <!--============= Pricing Section Starts Here =============-->
    <section
            class="pricing-section padding-top oh padding-bottom pb-lg-half bg_img pos-rel"
            data-background="./assets/images/bg/pricing-bg.jpg"
            id="pricing"
    >
        <div class="top-shape d-none d-md-block">
            <img src="./assets/css/img/top-shape.png" alt="css" />
        </div>
        <div class="bottom-shape d-none d-md-block mw-0">
            <img src="./assets/css/img/bottom-shape.png" alt="css" />
        </div>
        <div
                class="ball-2"
                data-paroller-factor="-0.30"
                data-paroller-factor-lg="0.60"
                data-paroller-type="foreground"
                data-paroller-direction="horizontal"
        >
            <img src="./assets/images/balls/1.png" alt="balls" />
        </div>
        <div
                class="ball-3"
                data-paroller-factor="0.30"
                data-paroller-factor-lg="-0.30"
                data-paroller-type="foreground"
                data-paroller-direction="horizontal"
        >
            <img src="./assets/images/balls/2.png" alt="balls" />
        </div>
        <div
                class="ball-4"
                data-paroller-factor="0.30"
                data-paroller-factor-lg="-0.30"
                data-paroller-type="foreground"
                data-paroller-direction="horizontal"
        >
            <img src="./assets/images/balls/3.png" alt="balls" />
        </div>
        <div
                class="ball-5"
                data-paroller-factor="0.30"
                data-paroller-factor-lg="-0.30"
                data-paroller-type="foreground"
                data-paroller-direction="vertical"
        >
            <img src="./assets/images/balls/4.png" alt="balls" />
        </div>
        <div
                class="ball-6"
                data-paroller-factor="-0.30"
                data-paroller-factor-lg="0.60"
                data-paroller-type="foreground"
                data-paroller-direction="horizontal"
        >
            <img src="./assets/images/balls/5.png" alt="balls" />
        </div>
        <div
                class="ball-7"
                data-paroller-factor="-0.30"
                data-paroller-factor-lg="0.60"
                data-paroller-type="foreground"
                data-paroller-direction="vertical"
        >
            <img src="./assets/images/balls/6.png" alt="balls" />
        </div>
        <div class="container">
            <div class="section-header pb-30 cl-white">
                <h2 class="title">Тарифы</h2>
                <p>
                    У Prime есть условия, от бесплатных до платных, которые
                    масштабируются в зависимости от ваших потребностей. Подпишитесь на
                    план, который соответствует размер вашего бизнеса.
                </p>
            </div>
            <div class="tab-up">
                <!-- <ul class="tab-menu pricing-menu">
                          <li class="active">Подписка </li>
                          <li>Yearly</li>
                      </ul> -->
                <div class="tab-area">
                    <div class="tab-item active">
                        <div class="pricing-slider-wrapper">
                            <div class="pricing-slider owl-theme owl-carousel">
                                <div class="pricing-item-2">
                                    <h5 class="cate">Демо</h5>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/pricing/pricing2.png"
                                                alt="pricing"
                                        />
                                    </div>
                                    <h2 class="title">$0</h2>
                                    <ul class="pricing-content-3">
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Доступ на 72
                                            часа
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Онлайн
                                            поддержка прямо в программе
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Бесплатные
                                            обновления
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Магазин
                                            аккаунтов
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Магазин прокси
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Обучающие
                                            материалы
                                        </li>
                                    </ul>
                                    <a
                                            href="#0"
                                            data-toggle="modal"
                                            data-target="#loginModal"
                                            class="get-button"
                                    >КУПИТЬ<i class="flaticon-right"></i
                                    ></a>
                                </div>
                                <div class="pricing-item-2">
                                    <h5 class="cate">Лицензия на месяц</h5>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/pricing/pricing3.png"
                                                alt="pricing"
                                        />
                                    </div>
                                    <h2 class="title">$125</h2>
                                    <ul class="pricing-content-3">
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Доступ на 72
                                            часа
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Онлайн
                                            поддержка прямо в программе
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Бесплатные
                                            обновления
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Магазин
                                            аккаунтов
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Магазин прокси
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Обучающие
                                            материалы
                                        </li>
                                    </ul>
                                    <a
                                            href="#0"
                                            data-toggle="modal"
                                            data-target="#loginModal"
                                            class="get-button"
                                    >КУПИТЬ<i class="flaticon-right"></i
                                    ></a>
                                </div>
                                <div class="pricing-item-2">
                                    <h5 class="cate">Клоннер чатов</h5>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/pricing/pricing3.png"
                                                alt="pricing"
                                        />
                                    </div>
                                    <h2 class="title">$345</h2>
                                    <ul class="pricing-content-3">
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Сбор истории
                                            чатов
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Подготовка
                                            аккаунт
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Редактор
                                            сообщений, редактируйте историю как вам нужно
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Постинг истории
                                            по интервалам или в реальном времени
                                        </li>
                                    </ul>
                                    <a
                                            href="#0"
                                            data-toggle="modal"
                                            data-target="#loginModal"
                                            class="get-button"
                                    >КУПИТЬ<i class="flaticon-right"></i
                                    ></a>
                                </div>
                                <div class="pricing-item-2">
                                    <h5 class="cate">РЕПОРТЕР</h5>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/pricing/pricing3.png"
                                                alt="pricing"
                                        />
                                    </div>
                                    <h2 class="title">$175</h2>
                                    <ul class="pricing-content-3">
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Возможность
                                            заблокировать канал конкурента
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Возможность
                                            заблокировать бота конкурентов
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Жалобы через
                                            инвайтинг в чаты
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Многопоточная
                                            работа
                                        </li>
                                    </ul>
                                    <a
                                            href="#0"
                                            data-toggle="modal"
                                            data-target="#loginModal"
                                            class="get-button"
                                    >КУПИТЬ<i class="flaticon-right"></i
                                    ></a>
                                </div>
                                <div class="pricing-item-2">
                                    <h5 class="cate">Клоннер каналов</h5>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/pricing/pricing3.png"
                                                alt="pricing"
                                        />
                                    </div>
                                    <h2 class="title">$105</h2>
                                    <ul class="pricing-content-3">
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Автономное
                                            наполнение каналов
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Замена ключевых
                                            слов + Выгрузка видео
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Клоннер старых
                                            постов
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Исключение
                                            нежелательного контента и добавление своего
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Многопоточная
                                            работа (наполняйте сотни каналов одновременно)
                                        </li>
                                    </ul>
                                    <a
                                            href="#0"
                                            data-toggle="modal"
                                            data-target="#loginModal"
                                            class="get-button"
                                    >КУПИТЬ<i class="flaticon-right"></i
                                    ></a>
                                </div>
                                <div class="pricing-item-2">
                                    <h5 class="cate">Перехватчик сообщений</h5>
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/pricing/pricing3.png"
                                                alt="pricing"
                                        />
                                    </div>
                                    <h2 class="title">$100</h2>
                                    <ul class="pricing-content-3">
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Поиск ключевых
                                            слов в чатах в режиме реального времени
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Распознает
                                            контекст сообщений с помощью нейросетей
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Пересылайте
                                            заявки куда вам нужно в канал, бот или аккаунт
                                        </li>
                                        <li class="list-group-item">
                                            <i class="fas fa-check text-success"></i>Используйте
                                            ключевые слова или фразы
                                        </li>
                                    </ul>
                                    <a
                                            href="#0"
                                            data-toggle="modal"
                                            data-target="#loginModal"
                                            class="get-button"
                                    >КУПИТЬ<i class="flaticon-right"></i
                                    ></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Cтрелочки-->
                    <button class="slider-button slider-previous" type="button">
                        <span class="visually-hidden">Показать предыдущий товар</span>
                    </button>
                    <button class="slider-button slider-next" type="button">
                        <span class="visually-hidden">Показать следующий товар</span>
                    </button>
                </div>
            </div>
        </div>
    </section>
  <!--============= Pricing Section Ends Here =============-->

  <!--============= Testimonial Section Starts Here =============-->
    <section
            class="testimonial-section padding-top pt-lg-half padding-bottom pos-rel oh"
            id="forumreviews"
    >
        <div class="container">
            <div
                    class="ball-3 style2 d-none d-lg-block"
                    data-paroller-factor="0.30"
                    data-paroller-factor-lg="-0.30"
                    data-paroller-type="foreground"
                    data-paroller-direction="horizontal"
            >
                <img src="./assets/images/client/circle2.png" alt="client" />
            </div>
            <div
                    class="ball-6 style2 d-none d-lg-block"
                    data-paroller-factor="-0.30"
                    data-paroller-factor-lg="0.60"
                    data-paroller-type="foreground"
                    data-paroller-direction="horizontal"
            >
                <img src="./assets/images/client/circle1.png" alt="client" />
            </div>
            <div
                    class="row justify-content-between flex-wrap-reverse align-items-center"
            >
                <div class="col-lg-7">
                    <div class="testimonial-wrapper style-two">
                        <a href="#0" class="testi-next trigger">
                            <img src="./assets/images/client/left.png" alt="client" />
                        </a>
                        <a href="#0" class="testi-prev trigger">
                            <img src="./assets/images/client/right.png" alt="client" />
                        </a>
                        <div
                                class="testimonial-area testimonial-slider owl-carousel owl-theme"
                        >
                            <div class="testimonial-item">
                                <div class="testimonial-thumb">
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/sponsor/sunrise.png"
                                                alt="client"
                                        />
                                    </div>
                                </div>
                                <div class="testimonial-content">
                                    <div class="ratings">
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                    </div>
                                    <p>Наш собственный, официальный форум</p>
                                    <h5 class="title">
                                        <a
                                                href="https://sunrise-protocol.info/threads/telegram-prime-mnogocelevoj-kombajn-po-rabote-s-telegramm.113/"
                                        >Sunrise Forum</a
                                        >
                                    </h5>
                                </div>
                            </div>
                            <div class="testimonial-item">
                                <div class="testimonial-thumb">
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/sponsor/antichat.png"
                                                alt="client"
                                        />
                                    </div>
                                </div>
                                <div class="testimonial-content">
                                    <div class="ratings">
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                    </div>
                                    <p>
                                        Очень известный и популярный форум в России. Пройдя по
                                        ссылке, вы найдете нашу ветку на форуме.
                                    </p>
                                    <h5 class="title">
                                        <a href="https://forum.antichat.club/threads/467458/"
                                        >ANTICHAT</a
                                        >
                                    </h5>
                                </div>
                            </div>
                            <div class="testimonial-item">
                                <div class="testimonial-thumb">
                                    <div class="thumb">
                                        <img
                                                src="./assets/images/sponsor/zetlab.png"
                                                alt="client"
                                        />
                                    </div>
                                </div>
                                <div class="testimonial-content">
                                    <div class="ratings">
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                    </div>
                                    <p>
                                        Крупнейший форум среди программ. По ссылке ниже вы найдете
                                        нашу ветку на форуме.
                                    </p>
                                    <h5 class="title">
                                        <a
                                                href="https://zennolab.com/discussion/threads/telegram-prime-mnogocelevoj-kombajn-po-rabote-s-telegramm.72430/"
                                        >ZennoLab</a
                                        >
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="testi-wrapper">
                        <div class="testi-header">
                            <div class="section-header left-style">
                                <h5 class="cate">Отзывы на форумах</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  <!--============= Testimonial Section Ends Here =============-->

  <!--============= Footer Section Starts Here =============-->
    <footer
            class="footer-section bg_img"
            data-background="./assets/images/footer/footer-bg.jpg"
    >
        <div class="container">
            <div class="footer-top padding-top padding-bottom">
                <ul class="social-icons">
                    <li>
                        <a href="#0"><i class="fab fa-vk"></i></a>
                    </li>
                    <li>
                        <a href="#0" class="active"><i class="fab fa-telegram"></i></a>
                    </li>
                </ul>
            </div>
            <div class="footer-bottom">
                <ul class="footer-link">
                    <li>
                        <a href="#0">О нас</a>
                    </li>
                    <li>
                        <a href="#0">ЧаВо</a>
                    </li>
                    <li>
                        <a href="#0">Контакты</a>
                    </li>
                </ul>
            </div>
            <div class="copyright">
                <p>Copyright © 2022.All Rights Reserved By <a href="#0">Prime</a></p>
            </div>
        </div>
    </footer>
  <!--============= Footer Section Ends Here =============-->

  <!--============= Modal Section Starts Here ============-->
    <div
            class="modal fade"
            id="loginModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <button
                            type="button"
                            class="close modal-close-button"
                            data-dismiss="modal"
                            aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <h4>Вход в личный кабинет</h4>
                        <hr />
                    </div>
                    <form>
                        <div class="d-flex flex-column text-left">
                            <div class="form-group">
                                <input
                                        type="email"
                                        class="form-control"
                                        id="email1"
                                        placeholder="Ваш e-mail..."
                                        required
                                        style="border-bottom: 2px solid #17a2b8"
                                />
                            </div>
                            <div class="form-group">
                                <input
                                        type="password"
                                        class="form-control"
                                        id="password1"
                                        placeholder="Ваш пароль..."
                                        required
                                        style="border-bottom: 2px solid #17a2b8"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <a
                                        class="my-1 modal-links-signin"
                                        href="#0"
                                        data-dismiss="modal"
                                        data-toggle="modal"
                                        data-target="#regModal"
                                        style="font-size: 15px"
                                >Ещё нет аккаунта?</a
                                >
                            </div>
                            <div class="col-6">
                                <a
                                        class="my-1 modal-links-signin"
                                        href="#0"
                                        data-dismiss="modal"
                                        data-toggle="modal"
                                        data-target="#forgotPassModal"
                                        style="font-size: 15px"
                                >Забыли пароль?</a
                                >
                            </div>
                        </div>
                        <div class="row mt-3">
                            <a
                                    class="login-modal-signin-link"
                                    href="panel.html"
                                    style="margin: auto"
                            >
                                <button
                                        type="submit"
                                        class="btn btn-info btn-block btn-round"
                                >
                                    Войти
                                </button>
                            </a>
                        </div>
                        <hr />
                        <div class="text-center text-muted delimiter">
                            Войти через сторонние сервисы:
                        </div>
                        <div class="d-flex justify-content-center social-buttons">
                            <button
                                    type="button"
                                    class="btn btn-secondary btn-round mx-1"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Twitter"
                            >
                                <i class="fab fa-google"></i>
                            </button>
                            <button
                                    type="button"
                                    class="btn btn-secondary btn-round mx-1"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Facebook"
                            >
                                <i class="fab fa-facebook"></i>
                            </button>
                            <button
                                    type="button"
                                    class="btn btn-secondary btn-round mx-1"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Linkedin"
                            >
                                <i class="fab fa-vk"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div
            class="modal fade"
            id="forgotPassModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <button
                            type="button"
                            class="close modal-close-button"
                            data-dismiss="modal"
                            aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <h4>Сброс пароля</h4>
                        <hr />
                    </div>
                    <div class="d-flex flex-column text-left">
                        <form>
                            <div class="form-group">
                                <input
                                        type="email"
                                        class="form-control"
                                        id="resetmail"
                                        placeholder="Введите почту"
                                        style="border-bottom: 2px solid #17a2b8"
                                        required
                                />
                            </div>

                            <div class="text-center">
                                <a
                                        class="my-2"
                                        href="#0"
                                        data-dismiss="modal"
                                        data-toggle="modal"
                                        data-target="#loginModal"
                                >Вспомнили пароль?</a
                                >
                            </div>
                            <div class="d-flex justify-content-center">
                                <a class="login-modal-signin-link" href="panel.html">
                                    <button
                                            type="submit"
                                            class="btn btn-info btn-block btn-round"
                                    >
                                        Сбросить пароль
                                    </button>
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
            class="modal fade"
            id="regModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <button
                            type="button"
                            class="close modal-close-button"
                            data-dismiss="modal"
                            aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-title text-center">
                        <h4>Регистрация</h4>
                        <hr />
                    </div>
                    <div class="d-flex flex-column text-left">
                        <form>
                            <div class="form-group">
                                <input
                                        type="email"
                                        class="form-control"
                                        id="emailReg"
                                        placeholder="Ваш e-mail..."
                                        style="border-bottom: 2px solid #17a2b8"
                                        required
                                />
                            </div>
                            <div class="form-group">
                                <input
                                        type="password"
                                        class="form-control"
                                        id="passwordReg"
                                        placeholder="Ваш пароль..."
                                        style="border-bottom: 2px solid #17a2b8"
                                        required
                                />
                            </div>

                            <div class="form-group">
                                <input
                                        type="password"
                                        class="form-control"
                                        id="passwordRegConfirm"
                                        placeholder="Подтвердите пароль..."
                                        style="border-bottom: 2px solid #17a2b8"
                                        required
                                />
                            </div>
                            <div class="d-flex justify-content-center">
                                <a
                                        class="my-2"
                                        href="#0"
                                        data-dismiss="modal"
                                        data-toggle="modal"
                                        data-target="#loginModal"
                                >Уже есть аккаунт?</a
                                >
                            </div>
                            <div class="d-flex justify-content-center">
                                <a class="login-modal-signin-link" href="panel.html">
                                    <button
                                            type="submit"
                                            class="btn btn-info btn-block btn-round"
                                    >
                                        Зарегистрироваться
                                    </button>
                                </a>
                            </div>
                            <hr />
                            <div class="text-center text-muted delimiter">
                                Войти через сторонние сервисы:
                            </div>
                            <div class="d-flex justify-content-center social-buttons">
                                <button
                                        type="button"
                                        class="btn btn-secondary btn-round mx-1"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Twitter"
                                >
                                    <i class="fab fa-google"></i>
                                </button>
                                <button
                                        type="button"
                                        class="btn btn-secondary btn-round mx-1"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Facebook"
                                >
                                    <i class="fab fa-facebook"></i>
                                </button>
                                <button
                                        type="button"
                                        class="btn btn-secondary btn-round mx-1"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Linkedin"
                                >
                                    <i class="fab fa-vk"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">

</style>
<script>


export default {
    name: 'fi',
}
</script>

<style scoped>

</style>
